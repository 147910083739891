.admin-dashboard {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.card-container {
  display: flex;
  justify-content: space-between;
}
.anno{
  cursor: pointer;
height: 38px;
margin-right: 09px;
}

.card-column {
  display: flex;
  flex-direction: column;
}

.card-common {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.birthdays-and-calendar {
  display: flex;
  margin-bottom: 30px;

}

.upcoming-birthdays,
.calendar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.upcoming-birthdays ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.upcoming-birthdays li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}

.initial {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #007bff;
  color: #ffffff;
  border-radius: 50%;
  margin-right: 10px;
}

.initial img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.birthday-info {
  flex-grow: 1;
}

.birthday-info .name {
  font-weight: bold;
  margin: 0;
}

.birthday-info .date {
  margin: 0;
  color: #666;
}

.time-left {
  color: #999;
}

.add-event-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
}

.add-event-button:hover {
  background-color: #0056b3;
}

.bKeNgyK4jHMqv9eOTyK6 {
  width: 100%;
}
.card-common.documents-expiring-soon .expiring-document-item {
  display: flex;
  align-items: center; /* Align icon and text horizontally */
  margin-bottom: 0.8rem; /* Reduce vertical spacing between items */
}

.card-common.documents-expiring-soon .initial {
  width: 35px;
  height: 35px;
  background-color: #ff0000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 0.8rem; /* Space between icon and text */
}

.card-common.documents-expiring-soon .document-info {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Align text vertically */
  line-height: 1.2; /* Reduce line spacing */
}

.card-common.documents-expiring-soon .name-and-type {
  font-weight: bold;
  margin: 0; /* Remove default margin */
}

.card-common.documents-expiring-soon .expiry-date {
  color: #888;
  font-size: 0.9rem;
  margin: 0; /* Remove default margin */
}

.card-common.documents-expiring-soon .no-expiring-documents {
  text-align: center;
  color: #888;
  font-style: italic;
}
.card-common {
  border-radius: 12px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 15px;
  transition: 0.3s ease-in-out;
}

.card-common:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.15);
}

/* Who’s Off / Working Remotely List */
.who-is-off-list {
  list-style: none;
  padding: 0;
}

.who-is-off-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.who-is-off-item:last-child {
  border-bottom: none;
}

.initial {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #007bff;
  color: white;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.absence-info {
  display: flex;
  flex-direction: column;
}

.name-and-status {
  font-weight: bold;
  color: #333;
}

.absence-dates {
  font-size: 14px;
  color: #666;
}

.no-one-off {
  text-align: center;
  color: #888;
  font-style: italic;
  padding: 10px;
}

.yet-to-punch-in-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.yet-to-punch-in-item {
  display: flex;
  align-items: center;
  gap: 12px; /* Spacing between icon and text */
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.yet-to-punch-in-item:last-child {
  border-bottom: none;
}

.yet-to-punch-in-item .initial {
  width: 40px;
  height: 40px;
  background-color: #007bff;
  color: white;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  flex-shrink: 0;
}

.employee-info {
  display: flex;
  flex-direction: column;
}

.name-and-type {
  font-weight: bold;
  margin: 0;
}

.employee-section {
  font-size: 14px;
  color: #555;
  margin: 0;
}
.yet-to-punch-in-container {
  max-height: 300px; /* Adjust the height as needed */
  overflow-y: auto;
  padding-right: 5px; /* Prevent content cutoff */
}

/* Optional: Style the scrollbar */
.yet-to-punch-in-container::-webkit-scrollbar {
  width: 8px;
}

.yet-to-punch-in-container::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
/* Cards */
.card-common {
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 15px;
  background-color: #fff;
}

/* Lists inside Cards */
.yet-to-punch-in-list,
.upcoming-birthdays-list {
  list-style: none;
  padding: 0;
}

.yet-to-punch-in-item,
.upcoming-birthday-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

/* Circle Initial */
.initial {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #007bff;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

/* Name & Info */
.name {
  font-weight: bold;
}

.job-title,
.time-left {
  font-size: 14px;
  color: gray;
}

/* Center Align Loading Spinner */
.text-center {
  text-align: center;
  margin-top: 20px;
}
/* Late Punch-Ins Card */
.card-common.late-punch-ins {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  padding: 15px;
  transition: 0.3s ease-in-out;
  height: 100%;
}

.card-common.late-punch-ins:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.15);
}

.late-punch-ins .expiring-document-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
}

.late-punch-ins .initial {
  width: 35px;
  height: 35px;
  background-color: purple;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 0.8rem;
  font-size: 16px;
}

.late-punch-ins .name {
  font-weight: bold;
  margin: 0;
}

.late-punch-ins p {
  margin: 0;
  font-size: 14px;
  color: #555;
}

.late-punch-ins .text-center {
  text-align: center;
  margin-top: 20px;
}

.late-punch-ins .card-body {
  max-height: 250px;
  overflow-y: auto;
}

/* Optional Scrollbar Styling */
.late-punch-ins .card-body::-webkit-scrollbar {
  width: 6px;
}

.late-punch-ins .card-body::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.card-common.late-punch-ins h5 {
  margin: 0;
  padding: 5px 0;
}

.card-common.late-punch-ins .card-header {
  padding: 10px;
}

.card-common.late-punch-ins .card-body {
  margin-top: 0;
  padding-top: 0;
}
